import {
  LineChart as LineGraph,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

import { scaleLog } from 'd3-scale';
const scale = scaleLog().base(Math.E);

export const LineChart = ({data}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineGraph width={document.body.clientWidth - 20} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          tickCount={5}
          dataKey="date"/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="minimum" stroke="red" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="maximum" stroke="green" />
        <Line type="monotone" dataKey="modal" stroke="blue" />
      </LineGraph>
    </ResponsiveContainer>
  )
}
