import {
  AreaChart as AreaGraph,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

import { curveCardinal } from 'd3-shape';
const cardinal = curveCardinal.tension(0.2);


export const AreaChart = ({data}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaGraph width={document.body.clientWidth - 20} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type={cardinal} dataKey="minimum" stroke="red" fill="#8884d8"/>
        <Area type={cardinal} dataKey="maximum" stroke="green" fill="#82ca9d"/>
        <Area type={cardinal} dataKey="modal" stroke="blue" fill="#ffc658"/>
      </AreaGraph>
    </ResponsiveContainer>
  )
}
