import Chip from '@mui/material/Chip';

export const ChipList = ({data, selected, onSelect}) => {
  return (
    data.map((elem) => {
      return (<Chip
                key={elem.id}
                onClick={() => onSelect(elem.id)}
                label={elem.name}
                variant={selected === elem.id ? 'filled' : 'outlined'}/>)      
    })
  )
}
