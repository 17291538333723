import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { DateSelect, DataTable, LineChart, AreaChart } from '../../components';
import { usePrices } from '../../hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Fab from '@mui/material/Fab';
import Select from '@mui/material/Select'
import EditLocationIcon from '@mui/icons-material/EditLocation';
import LinearProgress from '@mui/material/LinearProgress';

const fabStyle = {
  position: 'absolute',
  right: 16,
  bottom: 16
}

const chartHeight = 400
const tableHeight =  600

const Dashboard = () => {

  const navigate = useNavigate()

  const [ chartType, setChartType ] = useState('line')
  
  const { market, variety, start, end } = useParams() 

  const prices = usePrices({
    start,
    end,
    market,
    variety
  }) 

  const onSubmit = (event) => {
    navigate(`/dashboard/${market}/${variety}/${event.startDate}/${event.endDate}`)
  }

  if(!prices || prices?.length === 0) {
    return (<LinearProgress />)
  }
 
  console.log(`Rendering ${prices.length} number of records`);

  const renderChart = (type) => {
    switch (type) {
    case 'line':
      return (<LineChart data={prices} />);
    case 'area':
      return (<AreaChart data={prices} />);
    default:
      return (<LineChart data={prices} />);
    }
  }

  const navigateSelection = () => {
    navigate(`/${market}/${variety}/${start}/${end}`)
  }
  
  return (
    <>
      <Grid container spacing={2}>
        <DateSelect start={start} end={end} onSubmit={onSubmit} />
        <Grid display="flex" justifyContent="center" alignItems="center" xs={1}>
          <FormControl fullWidth>
            <InputLabel id="chart-type-select-label">Chart Type</InputLabel>
            <Select
              labelId="chart-type-select-label"
              id="chart-type-select"
              value={chartType}
              label="Chart Type"
              onChange={(event) => setChartType(event.target.value)}
            >
              <MenuItem value={'line'}>Line</MenuItem>
              <MenuItem value={'area'}>Area</MenuItem>
            </Select>
          </FormControl>          
        </Grid>        
      </Grid>      
      <Box sx={{ height: chartHeight, width: '100%' }}>
        { renderChart(chartType)}
      </Box>
      <Box sx={{ height: tableHeight, width: '100%' }}>
        <DataTable data={prices} />
      </Box>
      <Fab
        onClick={navigateSelection}
        sx={fabStyle}
      >
        <EditLocationIcon />
      </Fab>      
    </>    
  )
}

export default Dashboard;
