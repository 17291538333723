import { useLiveQuery } from "dexie-react-hooks";
import { database } from '../database/database';

export const usePrices = ({start, end, market, variety}) => {
  const prices = useLiveQuery(
    () =>
    database.prices
      .where('date')
      .between(start, end)
      .and((item) => item.market == market)
      .and((item) => item.variety == variety)
      .toArray(),
    [start, end, market, variety]
  )
  return prices;
}
