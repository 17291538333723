import Dexie from 'dexie';

export const database = new Dexie(process.env.REACT_APP_DATABASE_NAME);
database.version(1).stores({
  prices: '[date+market+variety], market, variety, minimum, maximum, modal, arrivals',
  markets: 'id, name',
  varieties: '[id+marketId], marketId, name',  
});


