import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';


export const DateSelect = ({onSubmit, start, end}) => {
  const [ startDate, setStartDate ] = useState(start);
  const [ endDate, setEndDate ] = useState(end);

  // FIXME: Handle out of range 
  const submit = () => {
    let start, end;
    if (typeof(startDate) !== 'string' && '_isAMomentObject' in startDate) {
      start = startDate.toISOString().split('T').at(0)
    }
    if (typeof(endDate) !== 'string' && '_isAMomentObject' in endDate) {
      end = endDate.toISOString().split('T').at(0)
    }
    onSubmit({
      startDate: start,
      endDate: end
    });
  }

  console.log(startDate, endDate)
  
  return (
    <>
      <Grid display="flex" justifyContent="center" alignItems="center">
        <Grid display="flex" justifyContent="center" alignItems="center">
          <MobileDatePicker
            label="Date mobile"
            inputFormat="YYYY-MM-DD"
            value={startDate}
            onChange={(event)=> setStartDate(event)}
            renderInput={(params) => <TextField {...params} />} />
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="center">
          <MobileDatePicker
            label="Date mobile"
            inputFormat="YYYY-MM-DD"
            value={endDate}
            onChange={(event)=> setEndDate(event)}
            renderInput={(params) => <TextField {...params} />} />
        </Grid>        
        <Grid display="flex" justifyContent="center" alignItems="center">
          <Button variant="contained" onClick={submit}> APPLY </Button>
        </Grid>
      </Grid>
    </>
  )
}
