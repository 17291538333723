import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";

import LinearProgress from '@mui/material/LinearProgress';
import Dashboard from './features/dashboard/Dashboard';
import Selection from './features/selection/Selection';

import { CustomSnackbar } from './components';

function App() {

  const [ message, setMessage ] = useState()

  useEffect(() => {
    /* eslint-disable no-restricted-globals */
    // const completed = localStorage.getItem('PAST_SYNC_COMPLETE')
    const bc = new BroadcastChannel('app-internal');
    bc.onmessage = (event) => {
      console.log(event)
      switch (event.data.type) {
      case 'LATEST_SYNC_COMPLETE':
        setMessage('Latest data sync success')
        break;
      case 'PAST_SYNC_COMPLETE':
        setMessage('Past data sync complete');
        localStorage.setItem(event.data.type, true);
        break;
      }
    }      
  })
  
  return (
    <>
      <CustomSnackbar
        open={message ? true : false}
        message={message}
        onAction={() => setMessage(null)}
      />      
      <Routes>
        <Route path="/" element={<Selection />}>
          <Route path="/:market" element={<Selection />} />
          <Route path="/:market/:variety" element={<Selection />} />
          <Route path="/:market/:variety/:start" element={<Selection />} />
          <Route path="/:market/:variety/:start/:end" element={<Selection />} />
        </Route>
        <Route path="/dashboard/:market/:variety" element={<Dashboard />} />
        <Route path="/dashboard/:market/:variety/:start" element={<Dashboard />} />
        <Route path="/dashboard/:market/:variety/:start/:end" element={<Dashboard />} />
      </Routes>
    </>
  );
}

export default App;
