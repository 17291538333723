import { useLiveQuery } from "dexie-react-hooks";
import { database } from '../database/database';

export const useVarieties = (market) => {
  const varieties = useLiveQuery(
    () =>
    database.varieties
      .where('marketId')
      .equals(market || '')
      .toArray(),
    [market]
  )
  return varieties;
}
