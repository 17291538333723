import { useLiveQuery } from "dexie-react-hooks";
import { database } from '../database/database';

export const useMarkets = () => {
  const markets = useLiveQuery(
    () =>
    database.markets
      .toArray()
  )
  return markets;
}
