import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Unstable_Grid2';
import Fab from '@mui/material/Fab';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

import { ChipList, CustomSnackbar } from '../../components';
import { useMarkets, useVarieties } from '../../hooks';

const fabStyle = {
  position: 'absolute',
  right: 16,
  bottom: 16
}

const Selection = () => {
  const navigate = useNavigate()
  const params = useParams()
  
  const [ market, setMarket ] = useState(params.market)
  const [ variety, setVariety ] = useState(params.variety)
  const [ error, setError ] = useState()
  
  const markets = useMarkets()
  const varieties = useVarieties(market) || []

  const navigateToDashboard = () => {
    if (!market) {
      setError('Please select the market')
      return;
    }
    else if (!variety) {
      setError('Please select variety')
      return;
    }
    if (!params.start) {
      if (!params.end) {
        const end = new Date().toISOString().split('T').at(0)
        let start = new Date()
        start.setMonth(start.getMonth() - 1)
        start.setDate(1)
        start = start.toISOString().split('T').at(0)
        navigate(`/dashboard/${market}/${variety}/${start}/${end}`)
      }
      else {
        const start = new Date().toISOString().split('T').at(0)
        navigate(`/dashboard/${market}/${variety}/${start}/${params.end}`)
      }      
    }
    else {
      if (!params.end) {
        let end = new Date()
        end.setMonth(end.getMonth() - 1)
        end.setDate(1)
        end = end.toISOString().split('T').at(0)
        navigate(`/dashboard/${market}/${variety}/${params.start}/${end}`)
      }
      else {
        navigate(`/dashboard/${market}/${variety}/${params.start}/${params.end}`)
      }
    }
    const end = new Date().toISOString().split('T').at(0)
    let start = new Date()
    start.setMonth(start.getMonth() - 6)
    start.setDate(1)
    start = start.toISOString().split('T').at(0)
    navigate(`/dashboard/${market}/${variety}/${start}/${end}`)    
  }
  
  if (!markets || markets?.length === 0) {
    return (<LinearProgress />)
  }
  return (
    <>
      <CustomSnackbar
        open={error ? true : false}
        message={error}
        onAction={() => setError(null)}
      />      
      <Grid container spacing={2}>
        <Grid>
          <ChipList
            data={markets}
            selected={market}
            onSelect={setMarket} />          
        </Grid>
        <Grid>
          <ChipList
            data={varieties}
            selected={variety}
            onSelect={setVariety} />
        </Grid>
        <Grid>
          <Fab
            onClick={navigateToDashboard}
            sx={fabStyle}
          >
            <DoneOutlineIcon />
          </Fab>
        </Grid>     
      </Grid>      
    </>
    
  )
}

export default Selection;
