import { DataGrid } from '@mui/x-data-grid';
import { CustomPagination } from '../CustomPagination/CustomPagination';
import Box from '@mui/material/Box';

export const DataTable = ({data}) => {
  const width = Math.floor(document.body.clientWidth / 5);

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
    },
    {
      field: 'maximum',
      headerName: 'Maximum',
      width: 80
    },
    {
      field: 'minimum',
      headerName: 'Minimum',
      width: 80
    },
    {
      field: 'modal',
      headerName: 'Modal',
      width: 80
    },
    {
      field: 'arrivals',
      headerName: 'Arrivals',
      width: 70
    }     
  ];  
  const getRowId = (params) => {
    const key = `${params.date}:${params.market}:${params.variety}`;
    return key
  }  
  return (
    <DataGrid
      getRowId={getRowId}
      rows={data}
      columns={columns}
      pageSize={7}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      components={{
        Pagination: CustomPagination,
      }}       
    />
  )
}
